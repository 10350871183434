import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from './container'
import Tags from './tags'
import * as styles from './article-preview.module.css'

const ArticlePreview = ({ posts }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null

  return (
    <Container>
      <div className='flex justify-center pt-24'>
      <ul className="flex flex-col w-full md:w-2/5 items-center">
        {posts.map((post) => {
          return (

            <Link to={`/blog/${post.slug}`} className="w-full">

            <li key={post.slug} class=" bg-white dark:bg-secondaryDark flex flex-row shadow w-90 h-32 mb-3 rounded-t">
              <GatsbyImage  class="object-fill h-full p-2 w-2/5 " image={post.heroImage.gatsbyImageData} />

              <div class="flex flex-col w-3/5 py-3 pl-3 pr-1 justify-between ">
                <div>
                  <h1 class="text-base mt-1 font-bold leading-none">{post.title}</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.description.childMarkdownRemark.html,
                    }}
                    class="text-sm pt-1 leading-none max-h-10"
                  />
                </div>
                <h4 className="text-sm ">{post.publishDate}</h4>
             {/*  <div className={styles.meta}>
                <small className="meta">{post.publishDate}</small>
                <Tags tags={post.tags} />
              </div> */}
              </div>

            </li>
            </Link>

          )
        })}
      </ul>
      </div>
    </Container>
  )
}

export default ArticlePreview
