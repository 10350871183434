import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'
import Image from '../components/image'
import ImageProfile from '../../assets/avatar.svg'

import IconGitHub from '../../assets/github.svg'
import IconInstagram from '../../assets/instagram.svg'
import IconLinkedin from '../../assets/linkedin.svg'
import IconTwitter from '../../assets/twitter.svg'
import IconYoutube from '../../assets/youtube.svg'
import { ThemeProvider } from '../components/theme-context';

import IconHome from '../../assets/home.svg'


class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')
    const [author] = get(this, 'props.data.allContentfulPerson.nodes')
    console.log(this.props.data);
    return (
      <ThemeProvider>
        <div className='h-full pt-10'>
        <div className="flex flex-col md:flex-row mx-auto max-w-3xl pt-24 md:pt-40 justify-center items-center content-center">
          <IconHome class="h-48 w-96 mx-auto "/>
          <div className='h-48 flex flex-col text-center md:text-left md:w-3/4  mt-10 md:pl-10 md:mt-0 mx-auto'>
            <h2 class="flex-auto text-3xl font-semibold text-gray-800 dark:text-white mt-5">Hi, <span class="bg-indigo-600 text-white rounded px-1">I’m Salvador</span></h2>

            <p class="flex-auto text-gray-800 mt-5 text-lg">I'm an iOS and Android developer. <br/> Here you can see some of my projects.</p>

            <div className="flex auto flex mt-3 mx-auto md:mx-0  w-72 place-content-around md:place-content-between">
              <a href="https://github.com/salvadordeveloper/" rel="noopener" target="_blank">
              <IconGitHub className="object-cover h-8 w-8"/>
              </a>
              <a href="https://www.youtube.com/c/SalvadorValverdeDev/" rel="noopener" target="_blank">
              <IconYoutube className="object-cover h-8 w-8"/>
              </a>
              <a href="https://www.linkedin.com/in/salvador-valverde/" rel="noopener" target="_blank">
              <IconLinkedin className="object-cover h-8 w-8"/>
              </a>
              <a href="https://twitter.com/salvadordevelop/" rel="noopener" target="_blank">
              <IconTwitter className="object-cover h-8 w-8"/>
              </a>
              <a href="https://www.instagram.com/salvadordeveloper/" rel="noopener" target="_blank">
              <IconInstagram className="object-cover h-8 w-8 "/>

              </a>
            </div>
        </div>

        </div>
        </div>


      </ThemeProvider>
    )
  }
}

export default RootIndex



export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      nodes {
        name
        shortBio {
          shortBio
        }
        title
        heroImage: image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
          )
        }
      }
    }
  }
`
